<template>
  <div class="el-wx-add-img position-relative">
    <img src="../../assets/forget/20.png" alt="" />
    <div class="wx-add-posi position-absolute top-0 left-0 direction">
      <!-- <div>请使用微信扫一扫</div>
      <img src="../../assets/login/922028.png" alt="" />
      <div class="font-normal">
        关注【干纺织】微信服务号绑定，关注后可及时获得投递进展、聊天消息
      </div> -->
      <div class="" id="login_container"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    showModel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },

  created() {
    if(this.showModel){
      this.getWXQRCode()
    }
  },
  mounted() {},

  methods: {
    // 微信生成二维码
    getWXQRCode() {
      this.$nextTick(() => {
        var obj = new WxLogin({
          self_redirect: false, // 这个为true的时候是直接在扫码的区域展现页面，我不需要，所以直接写false
          id: "login_container", // 我在上面定义的id名称
          appid: "wxb98bb66c3c7ae918", // 申请的网站应用appid
          scope: "snsapi_login", // 网页授权，目前网页只有这一种方式，静默授权
          redirect_uri: "https%3A%2F%2Fganfangzhi.com%2F%23%2Faccount", // 申请网站应用时填写的回调域名，这里对应即可
          state: Math.random(), // 简单的随机数+Session用来校验，
          style: "", // black、white两种可以选择，二维码样式（默认black）
          href: "", // 自定义样式链接，只能使用https链接或者base64编码的地址(修改二维码的样式，要经过base64位转码，地址为（https://the-x.cn/base64/）)
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-wx-add-img {
  width: 320px;
  height: 420px;
  img {
    width: 100%;
    height: 100%;
  }
  .wx-add-posi {
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    padding-top: 20px;
  }
  /deep/.lightBorder{
   width: 116px;
      height: 116px;
  }
}
</style>
